import React from 'react';
import styled from 'styled-components/macro';
import {
  Grid,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
} from '@material-ui/core';

const CardItem = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const CardItemContent = styled(CardContent)`
  flex-grow: 1;
`;
const CardItemMedia = styled(CardMedia)`
  padding-top: 56.25%;
`;

const BasicCard = ({ item, onClickAction }) => {
  const handleClick = () => {
    onClickAction(item.uid);
  };
  return (
    <CardItem>
      <CardItemMedia
        image={item.poster ? item.poster : 'https://source.unsplash.com/random'}
        title={item.title}
      />
      <CardItemContent>
        <Typography gutterBottom variant="h5" component="h2">
          {item.type}
        </Typography>
        <Typography>{item.description}</Typography>
      </CardItemContent>

      <CardActions>
        <Button size="small" color="primary" onClick={handleClick}>
          View
        </Button>
      </CardActions>
    </CardItem>
  );
};

export default BasicCard;

import React, { useState, useEffect } from 'react';
import {
  NavLink,
  Link,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { sessionList, sessionSet } from 'store/actions/session';
import { withFirebase } from 'api/Firebase';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'elements/Icon';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {
  List,
  ListItem,
  Grid,
  Box,
  Paper,
  ListItemIcon,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Container,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

import clsx from 'clsx';

import {
  mdiClose,
  mdiMenu,
  mdiAccountGroup,
  mdiViewDashboard,
  mdiCardOutline,
  mdiAccountBox,
  mdiGamepad,
  mdiChevronLeft,
  mdiAccountBoxMultiple,
  mdiAccountCircleOutline,
  mdiCards,
  mdiTelevision,
  mdiCart,
} from '@mdi/js';
import { makeStyles } from '@material-ui/core/styles';

import { withAuthorization } from 'api/Session';

import LogOutListItem from 'components/LogOutListItem';

import Dashboard from './Dashboard';
import Account from './Account';
import Sessions from 'scenes/Sessions';
// import ProjectStore from 'screens/ProjectStore';
// import MemberProjects from 'screens/MemberProjects';
// import ProjectSpace from 'pages/ProjectSpace';

import * as PATHS from 'routes/paths';
import { colors } from 'styles/default';

const drawerWidth = 240;
const SwipeableMemberDrawer = styled(SwipeableDrawer)`
  & .MuiDrawer-paper {
    min-width: 240px;
  }
`;

const MemberMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Footer = styled.footer`
  margin-top: auto;
  background-color: ${colors.secondary.light};
`;

const DrawerList = styled(List)`
  width: 250px;
`;
const DrawerMenu = styled.div`
  width: 100%;
  min-width: 250px;
`;
const ToolBuffer = styled.div`
  min-height: 56px;
`;

const MemberToolbar = styled(Toolbar)`
  justify-content: flex-end;
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  min-height: 56px;
  justify-content: flex-end;
`;

const DrawerListItem = styled(ListItem)`
  &&&: .active {
    background: #ff00ff;
  }
`;
const MemberAppBar = styled(AppBar)`
  justify-content: flex-end;
`;

const MainContainer = styled(Container)`
  /* height: 100vh; */
  padding-top: 2rem;
`;

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
const Member = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);

  const [ready, setReady] = useState(false);
  const [list, setList] = useState([]);
  const [errors, setErrors] = useState();

  useEffect(() => {
    const getSessionList = async () => {
      try {
        const list = await dispatch(sessionList(props.firebase));

        // const list = qs.docs.map(function (documentSnapshot) {
        //   return documentSnapshot.data();
        // });
        setList(list);
        setReady(true);
      } catch (error) {
        setErrors(error);
        setReady(true);
      }
    };

    getSessionList();
  }, [dispatch, props.firebase]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  function handleDrawerOpen() {
    setDrawerOpen(true);
  }

  function handleDrawerClose() {
    setDrawerOpen(false);
  }
  const sceneNav = (scene) => {
    console.log(`scene= ${scene}`);
    history.push(scene);
    setDrawerOpen(false);
  };

  return (
    <MemberMain>
      <HideOnScroll {...props}>
        <MemberAppBar>
          <MemberToolbar>
            <IconButton onClick={handleDrawerOpen}>
              <Icon inverse path={mdiMenu} />
            </IconButton>
          </MemberToolbar>
        </MemberAppBar>
      </HideOnScroll>
      <SwipeableMemberDrawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <Icon path={mdiClose} />
          </IconButton>
        </DrawerHeader>

        <Divider />
        <List>
          <ListItem
            button
            key="dashboard"
            onClick={() => sceneNav(PATHS.MEMBER)}
          >
            <ListItemIcon>
              <Icon path={mdiViewDashboard} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            key="account"
            onClick={() => sceneNav(PATHS.MEMBER_ACCOUNT)}
          >
            <ListItemIcon>
              <Icon path={mdiAccountCircleOutline} />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItem>

          <ListItem
            button
            key="sessions"
            onClick={() => sceneNav(PATHS.MEMBER_SESSIONS)}
          >
            <ListItemIcon>
              <Icon path={mdiTelevision} />
            </ListItemIcon>
            <ListItemText primary="Sessions" />
          </ListItem>
        </List>
        <Divider />
        <List>{<LogOutListItem />}</List>
      </SwipeableMemberDrawer>
      <ToolBuffer />
      <MainContainer maxWidth="md">
        <Switch>
          <Route path={PATHS.MEMBER_ACCOUNT} component={Account} />
          <Route path={PATHS.MEMBER_SESSIONS} component={Sessions} />
          <Route path={PATHS.MEMBER} component={Dashboard} />
        </Switch>
      </MainContainer>
    </MemberMain>
  );
};
const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(withFirebase(Member));

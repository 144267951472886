import React from 'react';
import Button from '@material-ui/core/Button';

const ButtonMain = ({
  type = 'button',
  variant = 'contained',
  color = 'primary',
  handleClick,
  children,
  ...other
}) => {
  return (
    <Button
      type={type}
      fullWidth
      variant={variant}
      color={color}
      onClick={handleClick}
      {...other}
    >
      {children}
    </Button>
  );
};

export default ButtonMain;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { withFirebase } from 'api/Firebase';

import queryString from 'query-string';
import { Redirect, withRouter } from 'react-router-dom';

import Loading from 'components/Loading';
import * as PATHS from 'routes/paths';
/* UI lib components */
import {
  FormContent,
  FormHeader,
  FormSubheader,
  FormFieldControl,
  FormFieldErrorText,
  FormFieldInput,
  FormFieldLabel,
} from 'elements/FormElements';

import ButtonMain from 'elements/ButtonMain';
import LandingFormLinks from 'components/LandingFormLinks';
// import LandingInfoText from '../../../elements/LandingInfoText';
// import ResetPassword from './ResetPassword';
// import VerifyEmail from './VerifyEmail';
// import NoMode from './NoMode';

const ReturnAction = ({ location, history, firebase }) => {
  const authUser = useSelector(state => state.sessionState.authUser);
  const [isReady, setIsReady] = useState(false);
  const [infoText, setInfoText] = useState();
  const [hasErrors, setHasErrors] = useState(false);
  const [errors, setErrors] = useState({});
  const [queryParams, setQueryParams] = useState({});
  const [userEmail, setUserEmail] = useState();
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    const params = queryString.parse(location.search);
    const mode = params.mode;
    const actionCode = params.oobCode;
    // const apiKey = params.apiKey ? params.apiKey : '';
    const continueUrl = params.continueUrl ? params.continueUrl : '/';
    const lang = params.lang ? params.lang : 'en';

    console.log(`mode _____- ${mode}`);

    if (mode === 'resetPassword') {
      firebase
        .doVerifyPasswordResetCode(actionCode)
        .then(email => {
          setUserEmail(email);
          setQueryParams({ mode, actionCode, continueUrl, lang });
          setInfoText('Verified! Please enter new Password.');
          setHasErrors(false);
          setErrors({});
          setIsReady(true);
        })
        .catch(error => {
          setQueryParams({ mode, actionCode, continueUrl, lang });
          setInfoText('');
          setHasErrors(true);
          setErrors({
            code: error.code,
            message: error.message,
          });
          setIsReady(true);
        });
    } else {
      //setQueryParams({mode,actionCode,continueUrl,lang});
      setInfoText('Incorrect or expired link');
      setHasErrors(false);
      setErrors({});
      setIsReady(true);
    }
  }, [firebase, location.search]);

  const handlePasswordResetForm = e => {
    //const { firebase, history } = this.props;
    // const { email, actionCode } = this.state;
    e.preventDefault();
    const { actionCode } = queryParams;

    firebase
      .doConfirmPasswordReset(actionCode, newPassword)
      .then(() => {
        firebase
          .doSignInWithEmailAndPassword(userEmail, newPassword)
          .then(() => {
            history.push(PATHS.MEMBER);
          });
        // firebase.login({
        //   email: email,
        //   password: password,
        // });
      })
      .catch(err => {
        setHasErrors(true);
        setErrors({
          code: err.code,
          message: err.message,
        });
        setIsReady(true);
      });
  };

  if (authUser && authUser.hasOwnProperty('uid') && authUser.uid !== undefined)
    return <Redirect to={PATHS.MEMBER} />;

  if (!isReady) {
    return <Loading />;
  }

  return (
    <form noValidate>
      <FormContent>
        {queryParams.mode === 'resetPassword' ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormHeader>Reset Password</FormHeader>
            </Grid>
            <Grid item xs={12}>
              <FormSubheader>Enter new password.</FormSubheader>
            </Grid>
            <Grid item xs={12}>
              <FormFieldControl fullWidth>
                <FormFieldLabel>For Email Address</FormFieldLabel>
                <FormFieldInput
                  fullWidth
                  id="email"
                  name="email"
                  value={userEmail}
                  readOnly
                />
              </FormFieldControl>
            </Grid>

            <Grid item xs={12}>
              <FormFieldControl fullWidth>
                <FormFieldLabel>Password</FormFieldLabel>
                <FormFieldInput
                  required
                  fullWidth
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />
              </FormFieldControl>

              {hasErrors && (
                <FormFieldErrorText>{errors.message}</FormFieldErrorText>
              )}
            </Grid>
            <Grid item xs={12}>
              <ButtonMain
                onClick={handlePasswordResetForm}
                label="Save New Password"
                fullWidth
              ></ButtonMain>
            </Grid>
            <Grid item xs={12}>
              <LandingFormLinks showSignin={true} showSignup={true} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormHeader>Error</FormHeader>
            </Grid>
            <Grid item xs={12}>
              <FormSubheader>{infoText}</FormSubheader>
            </Grid>
            <Grid item xs={12}>
              <LandingFormLinks showSignin={true} showSignup={true} />
            </Grid>
          </Grid>
        )}
      </FormContent>
    </form>
  );
};

export default withRouter(withFirebase(ReturnAction));

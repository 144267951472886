import { useState, useEffect } from 'react';
import { screenResize } from 'store/actions/app';
import { useDispatch } from 'react-redux';

const useWindowDimensions = () => {
  const dispatch = useDispatch();
  // //const windowSize = useWindowSize();
  // const windowDimensions = useWindowDimensions();
  // console.log(`windowDimensions=> ${windowDimensions.width}`);

  // const [dimensions, setDimensions] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      // setDimensions({
      //   width: window.innerWidth,
      //   height: window.innerHeight,
      // });
      dispatch(screenResize({ width, height }));
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);
  //return dimensions;
};

export default useWindowDimensions;

/**
 * ACTION TYPES
 */
import { SESSION_RESET, SESSION_LIST, SESSION_SET } from 'store/types';

export const sessionReset = () => (dispatch) => {
  return function (dispatch, getState) {
    return dispatch({
      type: SESSION_RESET,
    });
  };
};

const _getSessionList = (listRef) => async (dispatch) => {
  try {
    const snap = await listRef.get();
    const list = snap.docs.map((docsnap) => docsnap.data());
    return list;
  } catch (error) {
    return error;
  }
};
const _setSessionList = (list) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    dispatch({
      type: SESSION_LIST,
      list: list,
    });
    resolve(list);
  });
};

export const sessionList = (firebase) => async (dispatch) => {
  const listRef = firebase.fstore.collection('sessions');
  try {
    const dlist = await dispatch(_getSessionList(listRef));
    const list = await dispatch(_setSessionList(dlist));
    return list;
  } catch (error) {
    return error;
  }
};

const _getSessionById = (docRef) => async (dispatch) => {
  try {
    const docsnap = await docRef.get();
    const data = docsnap.data();
    return data;
  } catch (error) {
    return error;
  }
};
const _setSession = (uid, data) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    dispatch({
      type: SESSION_SET,
      current: data,
      currentId: uid,
    });
    resolve(uid);
  });
};

export const sessionSet = (firebase, uid) => async (dispatch) => {
  const listRef = firebase.fstore.collection('sessions');
  const docRef = listRef.doc(uid);
  try {
    const data = await dispatch(_getSessionById(docRef));
    return await dispatch(_setSession(uid, data));
  } catch (error) {
    return error;
  }
};

// export const sessionSet = (firebase, uid) => async (dispatch) => {
//   const currentId = uid;
//   //await dispatch(_setProjectId(pid));
//   const docRef = firebase.fstore.collection('sessions').doc(currentId);
//   const snap = await docRef.get();
//   const data = snap.data();
//   return new Promise(function (resolve, reject) {
//     dispatch({
//       type: SESSION_SET,
//       uid: currentId,
//       current: data,
//     });
//     resolve(currentId);
//   });
// };

import React from 'react';
import styled from 'styled-components/macro';

import { colors, fontFamily } from 'styles/default';
import { mediaMinDevice } from 'styles/responsive';
import {
  Grid,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
} from '@material-ui/core';

import BasicCard from 'components/BasicCard';
const CardListGrid = styled(Grid)`
  && {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

export const ViewHeader = styled.div`
  font-family: ${fontFamily.secondary};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.primary.dark};
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
  font-size: 1.8rem;
  ${mediaMinDevice.deviceM} {
    text-align: left;
    font-size: 2rem;
    line-height: 1.2;
  }
`;

const CardList = ({ title, list, handleClickAction }) => {
  return (
    <CardListGrid container spacing={4}>
      <Grid item xs={12}>
        <ViewHeader>{title}</ViewHeader>
      </Grid>
      {list.map((vid) => (
        <Grid item key={vid.uid} xs={12} sm={4} md={4}>
          <BasicCard item={vid} onClickAction={handleClickAction} />
        </Grid>
      ))}
    </CardListGrid>
  );
};

export default CardList;

import React from 'react';
import styled from 'styled-components/macro';
import { Link as BtnLink } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';

import * as PATHS from 'routes/paths';

const LandingLinkGrid = styled(Grid)``;
const LandingLinkElement = styled.div`
  margin-top: 0;
`;
const LandingBtnElement = styled.div`
  margin-top: 3rem;
`;
const LandingLinkButton = styled(BtnLink)``;
const FormElement = styled.form`
  padding: 1rem;
`;
const LandingFormLinks = ({
  showSignin = false,
  showSignup = false,
  showPasswordForget = false,
}) => {
  return (
    <LandingLinkGrid container justify="center" spacing={4}>
      {showPasswordForget && (
        <Grid item xs={12}>
          <LandingLinkElement>
            <Button component={BtnLink} to={PATHS.PASSWORD_FORGET}>
              Forgot password?
            </Button>
          </LandingLinkElement>
        </Grid>
      )}

      {showSignin && (
        <Grid item xs={12}>
          <LandingBtnElement>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              component={BtnLink}
              to={PATHS.SIGN_IN}
            >
              Already have an account? Sign In.
            </Button>
          </LandingBtnElement>
        </Grid>
      )}

      {showSignup && (
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            component={BtnLink}
            to={PATHS.SIGN_UP}
          >
            Don't have an account? Sign Up.
          </Button>
        </Grid>
      )}
    </LandingLinkGrid>
  );
};

export default LandingFormLinks;

import { SESSION_RESET, SESSION_LIST, SESSION_SET } from 'store/types';

const INITIAL_STATE = {
  currentId: null,
  current: null,
  list: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SESSION_RESET:
      return INITIAL_STATE;
    case SESSION_LIST: {
      return Object.assign({}, state, {
        list: action.list,
      });
    }
    case SESSION_SET: {
      return Object.assign({}, state, {
        currentId: action.currentId,
        current: action.current,
      });
    }
    // case SET_ACTIVITY_DATA: {
    //   return applySetActivityData(state, action);
    // }

    default:
      return state;
  }
}

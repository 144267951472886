export const LANDING = '/';
export const HOME = '/home';
export const PRICING = '/pricing';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const RETURN_ACTION = '/action';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const MEMBER = '/member';
export const MEMBER_ACCOUNT = '/member/account';
export const MEMBER_SESSIONS = '/member/sessions';
export const MEMBER_SESSIONS_VIEW = '/member/sessions/view';

// Material helpers
import { createMuiTheme } from '@material-ui/core';
import 'typeface-rubik';
import 'typeface-arvo';
import 'typeface-quicksand';
import 'typeface-oswald';
import 'typeface-chivo';
import 'typeface-comfortaa';
import { palette, typography } from 'styles/default';

// import overrides from "./overrides";

// const themeName = 'Purple Heart Pistachio Kestrel';
const themeName = 'Thunderbird Silver Longsnout Seahorse';

// const themeName = 'Purple Heart Malibu Quoll';
const theme = createMuiTheme({
  palette,
  // typography,
  // overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  typography,
  themeName,
});

export default theme;

import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import styled, { css } from 'styled-components/macro';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { withFirebase } from 'api/Firebase';

import * as PATHS from 'routes/paths';
import { sessionList, sessionSet } from 'store/actions/session';
import { mediaMinDevice } from 'styles/responsive';
import { useDispatch, useSelector } from 'react-redux';
import BasicCard from 'components/BasicCard';
const CardHero = styled.div`
  padding: 1rem;
`;

const CardGrid = styled(Grid)``;

const LandingFormTopRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex: 0 0;
  justify-content: center;
  height: 100px;
  padding: 1rem;
  ${mediaMinDevice.deviceM} {
    height: 200px;
    padding: 1.5rem;
  }
`;

const List = ({ firebase }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);

  const [ready, setReady] = useState(false);
  const [list, setList] = useState([]);
  const [errors, setErrors] = useState();

  useEffect(() => {
    const getSessionList = async () => {
      try {
        const list = await dispatch(sessionList(firebase));

        // const list = qs.docs.map(function (documentSnapshot) {
        //   return documentSnapshot.data();
        // });
        setList(list);
        setReady(true);
      } catch (error) {
        setErrors(error);
        setReady(true);
      }
    };

    getSessionList();
  }, [dispatch, firebase]);

  const navigateScene = async (uid) => {
    dispatch(sessionSet(firebase, uid)).then(() =>
      history.push(`${PATHS.MEMBER_SESSIONS_VIEW}`)
    );
  };
  return (
    <Container maxWidth="md">
      {/* Hero unit */}
      <CardHero>
        <Typography
          component="h1"
          variant="h1"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Training Sessions
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          paragraph
        >
          Something short and leading about the collection below—its contents,
          the creator, etc. Make it short and sweet, but not too short so folks
          don&apos;t simply skip over it entirely.
        </Typography>
      </CardHero>
      <CardGrid container spacing={4}>
        {ready &&
          list.map((vid) => (
            <Grid item key={vid.uid} xs={12} sm={6} md={4}>
              <BasicCard item={vid} onClickAction={navigateScene} />
            </Grid>
          ))}
      </CardGrid>
    </Container>
  );
};

export default withFirebase(List);

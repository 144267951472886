import React, { useState } from 'react';

import ButtonMain from 'elements/ButtonMain';
import { useHistory } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import styled, { css } from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';

import { withFirebase } from 'api/Firebase';
import {
  FormContent,
  FormHeader,
  FormSubheader,
  FormFieldControl,
  FormFieldErrorText,
  FormFieldInput,
  FormFieldLabel,
  FormLogoHeader,
} from 'elements/FormElements';
import LandingFormBtnLinks from 'components/LandingFormBtnLinks';
import * as PATHS from 'routes/paths';

import { mediaMinDevice } from 'styles/responsive';

const SignIn = props => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function onLogin(e) {
    e.preventDefault();
    try {
      await props.firebase.doSignInWithEmailAndPassword(email, password);
      // const { user } = userCred;

      // this.setState({ ...INITIAL_STATE });
      // await props.firebase.sendUserId(user.uid);

      history.push(PATHS.MEMBER);
    } catch (error) {
      setError(error);
      alert(error.message);
    }
  }
  return (
    <form noValidate onSubmit={onLogin}>
      <FormContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormHeader>Welcome Back!</FormHeader>
          </Grid>
          <Grid item xs={12}>
            <FormSubheader>Sign in to your account.</FormSubheader>
          </Grid>
          <Grid item xs={12}>
            <FormFieldControl fullWidth>
              <FormFieldLabel>Email Address</FormFieldLabel>
              <FormFieldInput
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={e => setEmail(e.target.value)}
                error={error && error.code !== 'auth/wrong-password'}
              />
              {error && error.code !== 'auth/wrong-password' && (
                <FormFieldErrorText>{error.message}</FormFieldErrorText>
              )}
            </FormFieldControl>
          </Grid>
          <Grid item xs={12}>
            <FormFieldControl fullWidth>
              <FormFieldLabel>Password</FormFieldLabel>
              <FormFieldInput
                error={error && error.code === 'auth/wrong-password'}
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              {error && error.code === 'auth/wrong-password' && (
                <FormFieldErrorText>{error.message}</FormFieldErrorText>
              )}
            </FormFieldControl>
          </Grid>

          <Grid item xs={12}>
            <ButtonMain type="submit" fullWidth>
              Sign In
            </ButtonMain>
          </Grid>
          <Grid item xs={12}>
            <LandingFormBtnLinks showPasswordForget={true} showSignup={true} />
          </Grid>
        </Grid>
      </FormContent>
    </form>
  );
};

export default withFirebase(SignIn);

import React from 'react';
import styled from 'styled-components/macro';
import ReactPlayer from 'react-player';
const StyledVideoWrapper = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
`;
const StyledVideo = styled.div`
  position: relative;
  -webkit-box-flex: 2;
  -ms-flex: 2 0 900px;
  flex: 2 0 900px;

  @media screen and (max-width: 1400px) {
    width: 100%;
    display: block;
  }
`;
const VidPlayer = ({ url }) => (
  <StyledVideo>
    <StyledVideoWrapper>
      <ReactPlayer
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: '0' }}
        playing={false}
        controls={true}
        url={url}
      />
    </StyledVideoWrapper>
  </StyledVideo>
);

export default VidPlayer;

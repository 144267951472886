import React from 'react';
import styled from 'styled-components/macro';
import VidPlayer from 'modules/VidPlayer';
import {
  Grid,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  CardHeader,
} from '@material-ui/core';
const ViewCardHeader = styled(CardHeader)``;

const CardItem = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const CardItemContent = styled(CardContent)`
  flex-grow: 1;
`;
const CardItemMedia = styled(CardMedia)``;

const ViewCard = ({ item, children }) => {
  return (
    <CardItem>
      <ViewCardHeader title={item.title} />
      <CardItemMedia>
        {' '}
        <VidPlayer url={item.url} />
      </CardItemMedia>
      <CardItemContent>
        <Typography gutterBottom variant="h5" component="h2">
          {item.type}
        </Typography>
        <Typography>{item.description}</Typography>
      </CardItemContent>
    </CardItem>
  );
};

export default ViewCard;

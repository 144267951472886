import { combineReducers } from 'redux';
import sessionState from './sessionState';
import app from './app';
import videoState from './videoState';
import session from './session';

const rootReducer = combineReducers({
  sessionState: sessionState,
  app: app,
  videoState: videoState,
  session,
});
export default rootReducer;

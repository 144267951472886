import React, { useState, useRef } from 'react';
// import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';

import { Grid, Checkbox } from '@material-ui/core';
import styled from 'styled-components/macro';
import * as _ from 'lodash';
import {
  FormFieldControl,
  FormFieldInput,
  FormFieldLabel,
} from 'elements/FormElements';
import useFormComplex from 'hooks/useFormComplex';

import { withFirebase } from 'api/Firebase';
// import PurchasedProjects from './PurchasedProjects';
import BtnMain from 'elements/BtnMain';

// import UserAvatar from 'components/UserAvatar';
import { Avatar } from '@material-ui/core';
import { colors, fontWeight } from 'styles/default';
import { stateSchema, validationSchema } from './schema';
import ChangePassword from './ChangePassword';
const UserAvatarBox = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  padding-bottom: 1rem;
`;
const UserAvatarBox2 = styled.div`
  display: flex;
  width: 160px;
  height: 160px;
`;
const UserAvatar = styled(({ isDesktop, ...other }) => <Avatar {...other} />)`
  && {
    height: 100%;
    width: 100%;
    background-color: ${colors.primary.dark};
    color: ${colors.primary.contrastText};
    font-size: 76px;
    font-weight: ${fontWeight.light};
    font-family: 'Montserrat';
  }
`;
const Profile = ({ member, firebase }) => {
  // const isDesktop = useSelector(state => state.app.isDesktop);
  // const [originalMemberData, setOriginalMemberData] = useState(member);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [newMemberData] = useState(member);
  // const [memberInitials, setMemberInitials] = useState();
  const [working, setWorking] = useState(false);

  // const [showProducts] = useState(false);
  // const [charges, setCharges] = useState([]);
  const memberDocRef = useRef(firebase.member(member.uid));

  const updateAccount = (inputs) => {
    setWorking(true);
    const { firstName, lastName, company, showJoyride } = inputs;
    const initials = myMemberInitials(firstName, lastName);
    const dateUpdated = new Date();
    const memberRef = memberDocRef.current;
    // console.log(`______${JSON.stringify(inputs)}`);

    // const updateData = {
    //   ...inputs,
    //   uid: productKey,
    //   addedDate: new Date(),
    // };
    memberRef
      .update({
        firstName,
        lastName,
        initials,
        company,
        showJoyride,
        dateUpdated,
      })
      .then(() => {
        setWorking(false);
      })
      .catch((error) => {
        setWorking(false);
        throw error;
      });
    // productsRef
    //   .child(productKey)
    //   .update(newpdata)
    //   .then(() => {
    //     dispatch(viewProduct(venueId, productKey)).then(id => {
    //       console.log(id);
    //       setWorking(false);
    //       history.push(`/venue/products/view`);
    //     });
    //     console.log(`addProd=${productKey}`);
    //   })
    //   .catch(error => {
    //     setWorking(false);
    //     throw error;
    //   });

    //   }
  };
  const {
    inputs,
    handleInputChange,
    handleCheckboxChange,
    handleSubmit,
  } = useFormComplex(
    stateSchema,
    validationSchema,
    newMemberData,
    updateAccount
  );

  const myMemberInitials = (first, last) => {
    const fInitial = first.charAt(0);
    const lInitial = last.charAt(0);
    const initials = `${fInitial}${lInitial}`;
    return _.toUpper(initials);
  };

  // useEffect(() => {
  //   const getChargesFun = firebase.funcs.httpsCallable('stripeGetCharges');
  //   const getCharges = async () => {
  //     const res = await getChargesFun();
  //     console.log(`charges=> ${JSON.stringify(res.data.data)}`);
  //     setCharges(res.data.data);
  //   };
  //   getCharges();
  // }, [firebase.funcs]);
  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  if (!inputs) {
    return null;
  }

  return (
    <Grid container item xs={12}>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <ChangePassword
          onSuccess={handleDialogClose}
          onCancel={handleDialogClose}
        />
      </Dialog>
      <Grid item xs={12}>
        <UserAvatarBox>
          <UserAvatarBox2>
            <UserAvatar isDesktop={true}>
              {myMemberInitials(inputs.firstName.value, inputs.lastName.value)}
            </UserAvatar>
          </UserAvatarBox2>
        </UserAvatarBox>
      </Grid>

      <Grid item xs={12}>
        <FormFieldControl fullWidth margin="normal">
          <FormFieldLabel>First Name</FormFieldLabel>
          <FormFieldInput
            id="firstName"
            name="firstName"
            type="text"
            margin="none"
            variant="outlined"
            value={inputs.firstName.value}
            onChange={handleInputChange}
          />
        </FormFieldControl>

        <FormFieldControl fullWidth margin="normal">
          <FormFieldLabel>Last Name</FormFieldLabel>
          <FormFieldInput
            id="lastName"
            name="lastName"
            type="text"
            margin="none"
            variant="outlined"
            value={inputs.lastName.value}
            onChange={handleInputChange}
          />
        </FormFieldControl>
      </Grid>

      <Grid item xs={12}>
        <FormFieldControl fullWidth margin="normal">
          <FormFieldLabel>Email</FormFieldLabel>
          <FormFieldInput
            id="email"
            name="email"
            type="text"
            margin="none"
            variant="outlined"
            value={newMemberData.email}
            readOnly
          />
        </FormFieldControl>
      </Grid>

      <Grid item xs={12}>
        <FormFieldControl margin="normal">
          <BtnMain
            working={working}
            fullWidth
            onClick={handleSubmit}
            label="Save"
          />
        </FormFieldControl>
      </Grid>
      <Grid item xs={12}>
        <FormFieldControl margin="normal">
          <BtnMain label="Change Password" onClick={handleDialogClickOpen} />
        </FormFieldControl>
      </Grid>
    </Grid>
  );

  // {showProducts && (
  //   <Grid item xs={12}>
  //     <Typography variant="body2" color="textSecondary" component="div">
  //       {charges && (
  //         <ul>
  //           {Object.keys(charges).map(key => (
  //             <li key={key}>
  //               {charges[key].id} - {charges[key].amount} -
  //               {charges[key].metadata.projectId}
  //             </li>
  //           ))}
  //         </ul>
  //       )}
  //     </Typography>
  //   </Grid>
  // )}
  // {showProducts && (
  //   <Grid item xs={12}>
  //     <PurchasedProjects uid={member.uid} />
  //   </Grid>
  // )}
};

export default withFirebase(Profile);

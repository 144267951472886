import React from 'react';

import { withRouter } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import styled, { css } from 'styled-components/macro';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { withFirebase } from 'api/Firebase';
import ButtonMain from 'elements/ButtonMain';

import {
  FormContent,
  FormHeader,
  FormSubheader,
  FormFieldControl,
  FormFieldErrorText,
  FormFieldInput,
  FormFieldLabel,
  FormLogoHeader,
} from 'elements/FormElements';
import LandingFormLinks from 'components/LandingFormLinks';

import Icon from 'elements/Icon';
import { mdiTelevision, mdiSolid } from '@mdi/js';
import * as PATHS from 'routes/paths';

import { mediaMinDevice } from 'styles/responsive';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroTopContent: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.light,
    borderWidth: 1,
    borderStyle: 'solid',
    padding: theme.spacing(8, 0, 6),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.light,
    borderWidth: 1,
    borderStyle: 'solid',
    padding: theme.spacing(8, 0, 6),
    marginTop: '2rem',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const LandingFormTopRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex: 0 0;
  justify-content: center;
  height: 100px;
  padding: 1rem;
  ${mediaMinDevice.deviceM} {
    height: 200px;
    padding: 1.5rem;
  }
`;

const Home = props => {
  const classes = useStyles();
  return (
    <Container maxWidth="md">
      <div className={classes.heroTopContent}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="h4"
              align="center"
              color="secondary"
            >
              Welcome to the
            </Typography>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="primary"
              gutterBottom
            >
              Home Made Player
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              Train like a pro, anywhere.... even at Home!
            </Typography>
          </Grid>
        </Grid>
      </div>

      {/* Hero unit */}
      <div className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Training Sessions
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Something short and leading about the collection below—its contents,
          the creator, etc. Make it short and sweet, but not too short so folks
          don&apos;t simply skip over it entirely.
        </Typography>
      </div>
      <div className={classes.heroButtons}>
        <Grid container spacing={2} justify="center">
          <Grid item>
            <Button variant="contained" color="primary">
              Main call to action
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary">
              Secondary action
            </Button>
          </Grid>
        </Grid>
      </div>

      {/* End hero unit */}
      <Grid className={classes.cardGrid} container spacing={4}>
        {cards.map(card => (
          <Grid item key={card} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image="https://source.unsplash.com/random"
                title="Image title"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  Session
                </Typography>
                <Typography>
                  This is a media card. You can use this section to describe the
                  content.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary">
                  Preview
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
  // return (
  //   <form noValidate>
  //     <FormContent>
  //       <Grid container spacing={2}>
  //         <Grid item xs={12}>
  //           <FormLogoHeader></FormLogoHeader>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <FormHeader>the Home Made Player!</FormHeader>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <FormSubheader>
  //             Train like a pro from anywhere, even your home.
  //           </FormSubheader>
  //         </Grid>

  //         <Grid item xs={12}>
  //           <LandingFormLinks showSignin={true} showSignup={true} />
  //         </Grid>
  //       </Grid>
  //     </FormContent>
  //   </form>
  // );
};

export default Home;

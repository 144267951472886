import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Button } from '@material-ui/core';
import { colors } from 'styles/default';
const Btn = styled(Button)`
  font-size: 1rem;
  text-align: left;
  border: none;
  border-radius: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 0.3rem 0.5rem;
  color: ${colors.buttons.btnLink.main.text};
  background-color: ${colors.buttons.btnLink.main.back};
  text-decoration: none;
  &:hover {
    color: ${colors.buttons.btnLink.hover.text};
    background-color: ${colors.buttons.btnLink.hover.back};
  }
  &&&&&&&:active {
    color: ${colors.buttons.btnLink.active.text};
    background-color: ${colors.buttons.btnLink.active.back};
  }
`;
const BtnLink = props => (
  <Btn component={NavLink} {...props}>
    {props.children}
  </Btn>
);
// const SidebarButtonLabel = styled.div`
//   text-align: left;
//   color: ${colors.text.primary};
//   text-decoration: none;
// `;

export default BtnLink;

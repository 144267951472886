export const AUTH_USER_SET = 'AUTH_USER_SET';

export const SCREEN_RESIZE = 'SCREEN_RESIZE';

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const REMOVE_ALL_ITEMS = 'REMOVE_ALL_ITEMS';
export const UPDATE_CART = 'UPDATE_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const VIDEO_RESET = 'VIDEO_RESET';
export const VIDEO_CURRENT_ID_SET = 'VIDEO_CURRENT_ID_SET';
export const SESSION_LIST = 'SESSION_LIST';
export const SESSION_SET = 'SESSION_SET';
export const SESSION_RESET = 'SESSION_RESET';

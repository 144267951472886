import React from 'react';
import { Link as BtnLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { FormLinkButton, FormLinkElement } from 'elements/FormElements';
import * as PATHS from 'routes/paths';
import { useSelector } from 'react-redux';

const LandingFormLinks = ({
  showSignin = false,
  showSignup = false,
  showPasswordForget = false,
}) => {
  const isDesktop = useSelector(state => state.app.isDesktop);
  return (
    <Grid container justify={isDesktop ? 'space-between' : 'center'}>
      {showSignin && (
        <Grid item>
          <FormLinkElement>
            <FormLinkButton
              component={BtnLink}
              to={PATHS.SIGN_IN}
              variant="body2"
            >
              Already have an account? Sign In.
            </FormLinkButton>
          </FormLinkElement>
        </Grid>
      )}

      {showPasswordForget && (
        <Grid item>
          <FormLinkElement>
            <FormLinkButton
              component={BtnLink}
              to={PATHS.PASSWORD_FORGET}
              variant="body2"
            >
              Forgot password?
            </FormLinkButton>
          </FormLinkElement>
        </Grid>
      )}

      {showSignup && (
        <Grid item>
          <FormLinkElement>
            <FormLinkButton
              component={BtnLink}
              to={PATHS.SIGN_UP}
              variant="body2"
            >
              Don't have an account? Sign Up.
            </FormLinkButton>
          </FormLinkElement>
        </Grid>
      )}
    </Grid>
  );
};

export default LandingFormLinks;

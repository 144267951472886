import { SCREEN_RESIZE } from 'store/types';
import { breakpoints } from 'styles/responsive';

// width >= xl
// ? 'xl'
// : width >= lg
// ? 'lg'
// : width >= md
// ? 'md'
// : width >= sm
// ? 'sm'
// : 'xs';
export const screenResize = (dimensions) => {
  const { width } = dimensions;
  const { sm, md, lg, xl } = breakpoints;
  const isDesktop = width > md;
  const screenSize =
    width < sm
      ? 'xs'
      : width < md
      ? 'sm'
      : width < lg
      ? 'md'
      : width < xl
      ? 'lg'
      : 'xl';

  return (dispatch, getState) => {
    dispatch({
      type: SCREEN_RESIZE,
      screenWidth: dimensions.width,
      screenHeight: dimensions.height,
      isDesktop: isDesktop,
      screenSize: screenSize,
    });
  };
};

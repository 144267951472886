import { breakpoints as defaultBreakpoints } from 'styles/default';
export const breakpoints = {
  ...defaultBreakpoints,
};
export const mediaMinPadding = {
  xs: 0,
  sm: 32,
  md: 288,
  lg: 288,
  xl: 288,
};
// export const deviceWidth = {
//   deviceS: `${breakpoints.sm}px`,
//   deviceM: `${breakpoints.md}px`,
//   deviceL: `${breakpoints.lg}px`,
//   mobileS: '320px',
//   mobileM: '375px',
//   mobileL: `${breakpoints.sm}px`,
//   tablet: `${breakpoints.md}px`,
//   tabletL: `${breakpoints.lg}px`,
//   laptop: '1024px',
//   laptopL: `${breakpoints.xl}px`,
//   desktop: '2560px',
// };
export const deviceWidth = {
  deviceS: `${breakpoints.sm}px`,
  deviceM: `${breakpoints.md}px`,
  deviceL: `${breakpoints.lg}px`,
  deviceXL: `${breakpoints.xl}px`,
};

export const deviceMin = {
  deviceS: `(min-width: ${deviceWidth.deviceS})`,
  deviceM: `(min-width: ${deviceWidth.deviceM})`,
  deviceL: `(min-width: ${deviceWidth.deviceL})`,
  deviceXL: `(min-width: ${deviceWidth.deviceXL})`,
};
export const deviceMax = {
  deviceS: `(max-width: ${deviceWidth.deviceS})`,
  deviceM: `(max-width: ${deviceWidth.deviceM})`,
  deviceL: `(max-width: ${deviceWidth.deviceL})`,
  deviceXL: `(max-width: ${deviceWidth.deviceXL})`,
};

export const mediaMinDevice = {
  deviceS: `@media (min-width: ${deviceWidth.deviceS})`,
  deviceM: `@media (min-width: ${deviceWidth.deviceM})`,
  deviceL: `@media (min-width: ${deviceWidth.deviceL})`,
  deviceXL: `@media (min-width: ${deviceWidth.deviceXL})`,
};
export const mediaMaxDevice = {
  deviceS: `@media (max-width: ${deviceWidth.deviceS})`,
  deviceM: `@media (max-width: ${deviceWidth.deviceM})`,
  deviceL: `@media (max-width: ${deviceWidth.deviceL})`,
  deviceXL: `@media (max-width: ${deviceWidth.deviceXL})`,
};

// export const device = {
//   mobileS: `(min-width: ${deviceWidth.mobileS})`,
//   mobileM: `(min-width: ${deviceWidth.mobileM})`,
//   mobileL: `(min-width: ${deviceWidth.mobileL})`,
//   tablet: `(min-width: ${deviceWidth.tablet})`,
//   tabletL: `(min-width: ${deviceWidth.tabletL})`,
//   laptop: `(min-width: ${deviceWidth.laptop})`,
//   laptopL: `(min-width: ${deviceWidth.laptopL})`,
//   desktop: `(min-width: ${deviceWidth.desktop})`,
//   desktopL: `(min-width: ${deviceWidth.desktop})`,
// };
// export const deviceMin = {
//   mobileS: `(min-width: ${deviceWidth.mobileS})`,
//   mobileM: `(min-width: ${deviceWidth.mobileM})`,
//   mobileL: `(min-width: ${deviceWidth.mobileL})`,
//   tablet: `(min-width: ${deviceWidth.tablet})`,
//   tabletL: `(min-width: ${deviceWidth.tabletL})`,
//   laptop: `(min-width: ${deviceWidth.laptop})`,
//   laptopL: `(min-width: ${deviceWidth.laptopL})`,
//   desktop: `(min-width: ${deviceWidth.desktop})`,
//   desktopL: `(min-width: ${deviceWidth.desktop})`,
// };
// export const deviceMax = {
//   mobileS: `(max-width: ${deviceWidth.mobileS})`,
//   mobileM: `(max-width: ${deviceWidth.mobileM})`,
//   mobileL: `(max-width: ${deviceWidth.mobileL})`,
//   tablet: `(max-width: ${deviceWidth.tablet})`,
//   tabletL: `(max-width: ${deviceWidth.tabletL})`,
//   laptop: `(max-width: ${deviceWidth.laptop})`,
//   laptopL: `(max-width: ${deviceWidth.laptopL})`,
//   desktop: `(max-width: ${deviceWidth.desktop})`,
//   desktopL: `(max-width: ${deviceWidth.desktop})`,
// };

// export const mediaMinDevice = {
//   mobileS: `@media (min-width: ${deviceWidth.mobileS})`,
//   mobileM: `@media (min-width: ${deviceWidth.mobileM})`,
//   mobileL: `@media (min-width: ${deviceWidth.mobileL})`,
//   tablet: `@media (min-width: ${deviceWidth.tablet})`,
//   tabletL: `@media (min-width: ${deviceWidth.tabletL})`,
//   laptop: `@media (min-width: ${deviceWidth.laptop})`,
//   laptopL: `@media (min-width: ${deviceWidth.laptopL})`,
//   desktop: `@media (min-width: ${deviceWidth.desktop})`,
//   desktopL: `@media (min-width: ${deviceWidth.desktop})`,
// };
// export const mediaMaxDevice = {
//   mobileS: `@media (max-width: ${deviceWidth.mobileS})`,
//   mobileM: `@media (max-width: ${deviceWidth.mobileM})`,
//   mobileL: `@media (max-width: ${deviceWidth.mobileL})`,
//   tablet: `@media (max-width: ${deviceWidth.tablet})`,
//   tabletL: `@media (max-width: ${deviceWidth.tabletL})`,
//   laptop: `@media (max-width: ${deviceWidth.laptop})`,
//   laptopL: `@media (max-width: ${deviceWidth.laptopL})`,
//   desktop: `@media (max-width: ${deviceWidth.desktop})`,
//   desktopL: `@media (max-width: ${deviceWidth.desktop})`,
// };
// export function mediaTag(rule, device) {
//   return `'@media (max-width: ${size[device]})'`;
// }

import { SCREEN_RESIZE } from 'store/types';

const initState = {
  screenWidth: typeof window === 'object' ? window.innerWidth : null,
  screenHeight: typeof window === 'object' ? window.innerHeight : null,
  isDesktop: false,
  screenSize: null,
};

const app = (state = initState, action) => {
  switch (action.type) {
    case SCREEN_RESIZE:
      return {
        ...state,
        screenWidth: action.screenWidth,
        screenHeight: action.screenHeight,
        isDesktop: action.isDesktop,
        screenSize: action.screenSize,
      };
    default:
      return state;
  }
};

export default app;

import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sessionSet } from 'store/actions/session';

import { ViewContainer, ViewGrid, ViewHeader } from 'containers/View';
import CardList from 'components/CardList';
import { withFirebase } from 'api/Firebase';
import * as PATHS from 'routes/paths';
import BasicCard from 'components/BasicCard';

const Dashboard = ({ firebase }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const [latest, setLatest] = useState([]);
  const [pastlist, setPastlist] = useState([]);
  const [favs, setFavs] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const list = session.list;
    setLatest(_.sampleSize(list, 1));
    setPastlist(_.sampleSize(list, 3));
    setFavs(_.sampleSize(list, 2));
    setReady(true);
  }, [session.list]);

  const navigateScene = async (uid) => {
    dispatch(sessionSet(firebase, uid)).then(() =>
      history.push(`${PATHS.MEMBER_SESSIONS_VIEW}`)
    );
  };
  return (
    <ViewContainer>
      {ready && (
        <ViewGrid container item xs={12}>
          <CardList
            title="Latest Session"
            list={latest}
            handleClickAction={navigateScene}
          />
          <CardList
            title="Favs"
            list={favs}
            handleClickAction={navigateScene}
          />
          <CardList
            title="History"
            list={pastlist}
            handleClickAction={navigateScene}
          />
        </ViewGrid>
      )}
    </ViewContainer>
  );
};

export default withFirebase(Dashboard);

import React from 'react';
import { useSelector } from 'react-redux';
import ViewCard from 'containers/ViewCard';

const View = () => {
  const session = useSelector((state) => state.session);
  return <ViewCard item={session.current} />;
};

export default View;

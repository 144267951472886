import React from 'react';
import MdiIcon from '@mdi/react';
import { colors } from 'styles/default';

const defColor = `${colors.buttons.btnIcon.main.text}`;
const invColor = `${colors.buttons.btnIcon.inverse.text}`;

const Icon = ({
  inverse = false,
  color = colors.buttons.btnIcon.main.text,
  size = '1.5rem',
  ...other
}) => {
  const colorText = inverse ? colors.buttons.btnIcon.inverse.text : color;
  return <MdiIcon size={size} color={colorText} {...other} />;
};

export default Icon;

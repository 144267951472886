import { rgba } from 'polished';
import * as _ from 'lodash';

export const main = {
  borderRadius: '0.8rem',
  overlay: rgba('black', 0.54),
  boxShadow: `0 2px 4px 0 ${rgba('black', 0.1)}`,
  paddingSide: '2.4rem',
  paddingTop: '2.4rem',
  radius: '6px',
  textSize: '1rem',
  radiusSmall: '3px',
  radiusBig: '290486px',
  padding: {
    xs: '0',
    sm: '0.5rem',
    md: '1rem',
    lg: '2.4rem',
  },
  BtnPaddingVert: `calc(0.5rem - 1px)`,
  BtnPaddingHorz: '3rem',
};

import React from 'react';
import { useSelector } from 'react-redux';
import Profile from 'screens/Profile';
import { ViewContainer, ViewGrid, ViewHeader } from 'containers/View';
const Account = () => {
  const authUser = useSelector((state) => state.sessionState.authUser);

  return (
    <ViewContainer>
      <ViewGrid container item xs={12}>
        <ViewHeader>Account Profile</ViewHeader>
        <ViewGrid container item xs={12}>
          <Profile member={authUser} />
        </ViewGrid>
      </ViewGrid>
    </ViewContainer>
  );
};
export default Account;

import { VIDEO_RESET, VIDEO_CURRENT_ID_SET } from 'store/types';

const INITIAL_STATE = {
  currentId: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case VIDEO_RESET:
      return INITIAL_STATE;
    case VIDEO_CURRENT_ID_SET: {
      return Object.assign({}, state, {
        currentId: action.currentId,
      });
    }

    // case SET_ACTIVITY_DATA: {
    //   return applySetActivityData(state, action);
    // }

    default:
      return state;
  }
}

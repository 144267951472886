import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import View from './View';
import List from './List';

const Sessions = ({ match }) => {
  return (
    <Switch>
      <Route component={View} path={`${match.url}/view`} />
      <Route component={List} path={`${match.url}`} />
    </Switch>
  );
};

export default Sessions;

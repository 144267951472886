import styled from 'styled-components/macro';
import { Container, Grid } from '@material-ui/core';
import { mediaMinDevice } from 'styles/responsive';

import { colors } from 'styles/default';
export const ViewHeader = styled.div`
  font-family: Montserrat;

  font-weight: bold;
  font-style: normal;
  font-stretch: normal;

  letter-spacing: normal;
  color: ${colors.primary.dark};
  text-transform: uppercase;
  margin-bottom: 0.7rem;
  line-height: 1.2;
  text-align: center;
  font-size: 1.8rem;
  ${mediaMinDevice.deviceM} {
    margin-bottom: 1rem;
    text-align: left;
    font-size: 2rem;
    line-height: 1.2;
  }
`;

export const ViewSubHeader = styled.div`
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.common.black};
  margin-bottom: 1.8rem;
  line-height: 1;
  text-align: center;
  font-size: 1.2rem;
  ${mediaMinDevice.deviceM} {
    margin-bottom: 2rem;
    text-align: left;
    font-size: 1.4rem;
    line-height: 1.2;
  }
`;

export const ViewInnerHeader = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.primary.dark};
  text-transform: uppercase;
  /* font-size: ${(props) => (props.screenSize === 'sm' ? '20px' : '30px')};
  text-align: ${(props) => (props.screenSize === 'xs' ? 'center' : 'left')};
  margin: ${(props) =>
    props.screenSize === 'xs' ? '10px 0 10px 0' : '15px 0 15px 0'};
  padding: ${(props) =>
    props.screenSize === 'xs' ? '5px 0 5px 0' : '10px 0 10px 0'}; */
  /* margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 10px; */
  text-align: center;
  line-height: 1;
  font-size: 1.5rem;
  margin: 10px 0 10px 0;
  padding: 5px 0 5px 0;
   ${mediaMinDevice.deviceS} {
    font-size: 1.75rem;
    text-align: left;
    line-height: 1.2;
  }
  ${mediaMinDevice.deviceM} {
    font-size: 2em;
    text-align: left;
    line-height: 1.2;
  }
`;

export const ViewGrid = styled(Grid)`
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
`;
export const ViewContainer = styled(Container)``;

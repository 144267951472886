import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { colors, fontFamily } from 'styles/default';
import { hexToRGBa } from 'styles/utils';
import { CircularProgress } from '@material-ui/core';

// const BtnWrapper = styled.div`
//   display: relative;
// `;
const WorkingWrapper = styled.div`
  position: relative;
`;
const WorkingProgress = styled(CircularProgress)`
  color: ${colors.buttons.btnMain.main.text};
  /* position: absolute; */

  /* left: 50%;
  margin-top: -12;
  margin-left: -12; */
`;
const Btn = styled.button`
  font-family: ${fontFamily.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  margin: 0;
  cursor: pointer;
  color: ${(props) => props.mainColor};
  background-color: ${(props) => props.backColor};
  border-color: ${(props) => props.mainColor};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;

  min-width: 100px;
  height: 40px;

  &:hover {
    color: ${(props) => props.mainColor};
    background-color: ${(props) => props.hoverColor};
  }
  &:disabled {
    color: ${colors.buttons.btnMain.disabled.text};
    background-color: ${colors.buttons.btnMain.disabled.back};
    cursor: not-allowed;
  }
  &:focus {
    outline-color: ${colors.buttons.btnMain.active.border};
  }
  padding: 0.25rem 1rem;

  /* margin-bottom: 25px; */
`;
const BtnLabel = styled.div`
  font-family: ${fontFamily.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1;
  margin: 0;
  width: 100%;
  min-height: 24px;
`;

const BtnMain = ({
  label,
  mainColor = `${colors.buttons.btnMain.main.text}`,
  backColor = `${colors.buttons.btnMain.main.back}`,
  working,
  ...props
}) => {
  const [mainClr, setMainClr] = useState();
  const [backClr, setBackClr] = useState();
  const [hoverClr, setHoverClr] = useState();

  useMemo(() => {
    const mcolor = hexToRGBa(mainColor, 1);
    const bcolor = hexToRGBa(backColor, 0.28);
    const hcolor = hexToRGBa(backColor, 0.6);
    setMainClr(mcolor);
    setBackClr(bcolor);
    setHoverClr(hcolor);
  }, [mainColor, backColor]);

  console.log(
    `textColor=>${mainClr} backColor=> ${backClr} hoverColor=> ${hoverClr}`
  );
  // const tcolor = hexToRGBa(textColor, 1);
  // const fcolor = hexToRGBa(fillColor, 0.25);
  // const hcolor = hexToRGBa(fillColor, 0.6);
  return (
    <Btn
      mainColor={mainClr}
      backColor={backClr}
      hoverColor={hoverClr}
      {...props}
    >
      {working ? (
        <WorkingWrapper>
          <WorkingProgress
            disableShrink={true}
            variant="indeterminate"
            size={24}
          />
        </WorkingWrapper>
      ) : (
        <BtnLabel>{label}</BtnLabel>
      )}
    </Btn>
  );
};

export default BtnMain;

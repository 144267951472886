import React, { useState } from 'react';
import { NavLink, Switch, Route, Redirect } from 'react-router-dom';

import styled from 'styled-components/macro';

import Icon from 'elements/Icon';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {
  List,
  ListItem,
  Grid,
  Paper,
  ListItemIcon,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Container,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { mdiMenu, mdiChevronLeft, mdiClose } from '@mdi/js';
import BtnLink from 'elements/BtnLink';
import { mediaMinDevice } from 'styles/responsive';
import Home from './Home';
import Pricing from './Pricing';
import SignIn from './SignIn';
import SignUp from './SignUp';
import PasswordForget from './PasswordForget';
import ReturnAction from './ReturnAction';
import * as PATHS from 'routes/paths';
import { colors } from 'styles/default';

import landingBack from 'assets/imgs/grass.jpg';
import Membership from './Membership';

const LandingMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Footer = styled.footer`
  margin-top: auto;
  background-color: ${colors.secondary.light};
`;

const DrawerList = styled(List)`
  width: 250px;
`;
const DrawerMenu = styled.div`
  width: 100%;
  min-width: 250px;
`;
const ToolBuffer = styled.div`
  min-height: 56px;
`;

const LandingToolbar = styled(Toolbar)`
  justify-content: flex-end;
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  min-height: 56px;
  justify-content: flex-end;
`;

const DrawerListItem = styled(ListItem)`
  &&&: .active {
    background: #ff00ff;
  }
`;
const LandingAppBar = styled(AppBar)`
  justify-content: flex-end;
`;

const MainContainer = styled(Container)`
  /* height: 100vh; */
  padding-top: 2rem;
`;
const ImageGrid = styled(Grid)`
  background-image: url('./imgs/background.jpg');
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  background-position: center;
`;

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
const Landing = props => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = e => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = e => {
    setDrawerOpen(false);
  };
  return (
    <LandingMain>
      <HideOnScroll {...props}>
        <LandingAppBar>
          <LandingToolbar>
            <IconButton onClick={handleDrawerOpen}>
              <Icon inverse path={mdiMenu} />
            </IconButton>
          </LandingToolbar>
        </LandingAppBar>
      </HideOnScroll>
      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <Icon path={mdiClose} />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <DrawerMenu>
          <BtnLink to={PATHS.HOME}>Home</BtnLink>
          <BtnLink to={PATHS.PRICING}>Pricing</BtnLink>
          <BtnLink to={PATHS.SIGN_UP}>Sign Up</BtnLink>
          <BtnLink to={PATHS.SIGN_IN}>Sign In</BtnLink>
        </DrawerMenu>
      </SwipeableDrawer>
      <ToolBuffer />
      <MainContainer maxWidth="md">
        <Switch>
          <Route component={ReturnAction} path={PATHS.RETURN_ACTION} />
          <Route component={PasswordForget} path={PATHS.PASSWORD_FORGET} />
          <Route component={SignUp} path={PATHS.SIGN_UP} />
          <Route component={SignIn} path={PATHS.SIGN_IN} />
          <Route component={Pricing} path={PATHS.PRICING} />
          <Route component={Home} path={PATHS.HOME} />
          <Route>
            <Redirect to={PATHS.HOME} />
          </Route>
        </Switch>
      </MainContainer>
    </LandingMain>
  );
};
export default Landing;

{
  /* <Footer>
<Container maxWidth="md">My sticky footer can be found here</Container>
</Footer> */
}

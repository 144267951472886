import { rgba } from 'polished';

export const palette = {
  primary: {
    main: '#B71C1C',
    light: 'rgb(197, 73, 73)',
    dark: 'rgb(128, 19, 19)',
    contrastText: '#fff',
  },
  secondary: {
    main: '#BDBDBD',
    light: 'rgb(202, 202, 202)',
    dark: 'rgb(132, 132, 132)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  success: {
    contrastText: '#fff',
    main: '#45B880',
    light: '#F1FAF5',
    dark: '#00783E',
  },
  info: {
    contrastText: '#fff',
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489',
  },
  warning: {
    contrastText: '#fff',
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E',
  },
  danger: {
    contrastText: '#fff',
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08',
  },
  tonalOffset: 0.2,
  background: { paper: '#fff', default: '#fafafa' },
  contrastThreshold: 3,
  grey: {
    '50': '#fafafa',
    '100': '#f5f5f5',
    '200': '#eeeeee',
    '300': '#e0e0e0',
    '400': '#bdbdbd',
    '500': '#9e9e9e',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#212121',
    A700: '#616161',
    A100: '#d5d5d5',
    A400: '#303030',
    A200: '#aaaaaa',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  common: { black: '#000', white: '#fff' },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  type: 'light',
  action: {
    hoverOpacity: 0.08,
    hover: 'rgba(0, 0, 0, 0.08)',
    selected: 'rgba(0, 0, 0, 0.14)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    active: 'rgba(0, 0, 0, 0.54)',
  },
};

export const colors = {
  primary: palette.primary,
  secondary: palette.secondary,
  background: { paper: '#fff', default: '#fafafa' },
  text: {
    primary: palette.primary,
    secondary: palette.secondary,
    disabled: palette.disabled,
    hint: 'rgba(0, 0, 0, 0.38)',
    error: palette.danger,
  },
  buttons: {
    btnLink: {
      main: {
        text: palette.primary.main,
        back: palette.primary.contrastText,
        border: palette.primary.main,
      },
      hover: {
        text: palette.primary.main,
        back: palette.secondary.light,
        border: palette.primary.main,
      },
      active: {
        text: palette.primary.contrastText,
        back: palette.primary.dark,
        border: palette.primary.contrastText,
      },
      inverse: {
        text: palette.primary.contrastText,
        back: palette.primary.dark,
        border: palette.primary.light,
      },
    },
    btnIcon: {
      main: {
        text: palette.primary.main,
        inverse: palette.primary.contrastText,
        back: palette.primary.contrastText,
        border: palette.primary.main,
      },
      inverse: {
        text: palette.primary.contrastText,
        back: palette.primary.dark,
        border: palette.primary.light,
      },
      hover: {
        text: palette.primary.main,
        back: palette.primary.light,
        border: palette.primary.main,
      },
      active: {
        text: palette.primary.contrastText,
        back: palette.primary.dark,
        border: palette.primary.contrastText,
      },
    },
    btnMain: {
      main: {
        text: palette.primary.main,
        inverse: palette.primary.contrastText,
        back: '#C54949',
        border: palette.primary.main,
      },
      disabled: {
        text: palette.action.disabled,
        back: palette.action.disabledBackground,
        border: palette.action.disabled,
      },
      inverse: {
        text: palette.primary.contrastText,
        back: palette.primary.dark,
        border: palette.primary.light,
      },
      hover: {
        text: palette.primary.main,
        back: palette.primary.light,
        border: palette.primary.main,
      },
      active: {
        text: palette.primary.contrastText,
        back: palette.primary.dark,
        border: palette.primary.contrastText,
      },
    },
  },
  fields: {
    input: {
      main: {
        text: palette.text.primary,
        back: palette.primary.contrastText,
        border: palette.primary.main,
      },
      focus: {
        text: palette.text.primary,
        back: rgba(183, 28, 28, 0.3),
        border: palette.primary.main,
      },
    },
  },
  cards: {
    landing: {
      header: {
        back: palette.secondary.light,
        text: palette.primary.dark,
      },
    },
  },
  table: {
    mainView: {
      textColor: palette.primary.dark,
      backColor: '#fff',
    },
    detailView: {
      textColor: palette.primary.dark,
      backColor: rgba(118, 203, 226, 0.6),
    },
  },
  toggleSwitch: {
    trackFill: '#76cbe2',
    trackBorder: '#345ca4',
    off: '#9e9e9e',
    on: '#282964',
  },
  iconButton: {},
  dialogElements: {
    header: {
      backColor: {
        main: palette.primary.contrastText,
        darkMode: 'rgba(0, 0, 98,1)',
      },
      textColor: {
        main: 'rgba(0, 0, 98,1)',
        darkMode: '#ffffff',
      },
    },
  },
  formElements: {
    label: {
      textColor: {
        main: 'rgba(0, 0, 98,1)',
        contrast: '#ffffff',
      },
    },
  },

  formButton: {
    activeBackColor: '#76cbe2',
    activeTextColor: '#282964',
    hoverBackColor: '#ffc260',
    hoverTextColor: '#ffffff',
  },
  project: {
    header: {
      label: 'rgba(0, 0, 98, .7)',
      value: 'rgba(0, 0, 98, 1)',
    },
    subheader: {
      label: 'rgba(117, 117, 117, .66)',
      value: 'rgba(117, 117, 117, 1)',
    },
  },
  formCards: {
    view: {
      backColor: 'rgba(224, 224, 224, .3)',
    },
  },
  screens: {
    main: {
      backColor: '#ffffff',
      borderColor: '#9e9e9e',
      dirtyColor: 'rgba(51, 51, 163, .4)',
    },
    form: {
      backColor: '#eeeeee',
      dirtyColor: 'rgba(51, 51, 163, .4)',
    },
    view: {
      backColor: 'rgba(250, 250, 250, 1)',
    },
  },
  screenCards: {
    fieldForm: {
      backColor: '#ffffff',
      dirtyColor: '#ede7f6',
    },
    form: {
      backColor: '#9e9e9e',
      dirtyColor: 'rgba(51, 51, 163, .4)',
    },
    view: {
      backColor: 'rgba(250, 250, 250, 1)',
    },
  },
  panelInfo: {
    header: {
      box1: {
        backColor: '#ffffff',
      },
      box2: {
        backColor: 'rgba(224, 224, 224, .3)',
      },
    },
    footer: {
      box1: {
        backColor: '#ffffff',
      },
    },
  },
  panel: {
    backColor: '#9e9e9e',
    isDirty: 'rgba(51, 51, 163, .4)',
  },

  textArea: {
    focusBack: palette.primary.light,
  },

  divider: 'rgba(0, 0, 0, 0.12)',
  common: { black: '#000', white: '#fff' },
  btnResult: {
    fill: '#fff',
    active: `rgba(118, 203, 226, .25)`,
    text: 'rgba(40,41, 100, 1)',
    hover: 'rgba(40,41, 100, .25)',
  },
  member: {
    backColor: 'rgba(118, 203,226, 0)',
    sidebarColor: 'rgba(255,255,255,1)',
    sidebarHover: `rgba(118, 203, 226, .2)`,
  },
  template: {
    blueLighter: 'rgba(118,203, 226, 0.25)',
    highlightGreen: '#c6ff00',
    highlightBlue: '#cfe2f3',
  },
};
